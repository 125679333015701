import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Section } from 'app/components/Common/Section'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  label?: string
  languageCode: string
  services: ServiceProps[]
  variant?: string
}

export const ServicesList = memo(function ServicesList({
  label = 'comfort',
  languageCode,
  services,
  variant = 'default',
}: Props) {
  if (services.length < 1) {
    return null
  }
  return (
    <Container>
      <Section label={label} languageCode={languageCode} />
      <Wrapper>
        <FadeInUp>
          <Services row wrap>
            {services.map((item: any, index) => (
              <Service variant={variant} key={index} {...item} />
            ))}
          </Services>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto 0;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 6.25rem;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 1.875rem 1.875rem 5.625rem;
  text-align: center;
`

const Services = styled(FlexBox)`
  max-width: 64rem;
  margin: 0 auto;
`
