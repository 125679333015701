import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useState } from 'react'

export interface Props {
  images: ImageProps[]
  languageCode: string
}

export const Slider = memo(function Slider({ images, languageCode }: Props) {
  if (!images) {
    return null
  }

  const [_currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    slides: {
      perView: 1.01,
      spacing: 30,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1023px)': {
        slides: {
          perView: 1.075,
          spacing: 30,
        },
      },
    },
  })

  return (
    <Container>
      <Carousel className="slider" ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide key={index} className="keen-slider__slide">
            <Inner>
              <Image media="(min-width: 2800px)" {...item} />
            </Inner>
          </Slide>
        ))}
      </Carousel>

      {images && images.length > 1 ? (
        <Arrows>
          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
            variant="default"
          />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
            variant="default"
          />
        </Arrows>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  cursor: pointer;
  position: relative;
  overflow: visible;
`

const Carousel = styled.div`
  display: flex;
  width: 100%;
  height: 77.777vh;
  outline: none;
  overflow: visible;
  position: relative;

  @media (max-width: 1199px) {
    height: 70vh;
  }
  @media (max-width: 1023px) {
    height: 75vw;
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  right: 4.5625rem;
  transform: translateY(-50%);
  z-index: 2;
  flex-direction: column-reverse;

  > div {
    border-radius: 50%;

    &:first-of-type {
      margin-top: 1.125rem;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`
