import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Mq, Users } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useEffect } from 'react'
import {
  animateScroll,
  animateScroll as scroll,
  Element,
  Events,
  Link,
  scroller,
  scrollSpy,
} from 'react-scroll'

import { Slider } from './Slider'

export interface Props {
  beButtonURL?: string
  description?: string
  images?: ImageProps[]
  labelFilter?: string
  languageCode: string
  pax?: string
  quotationPageURL?: string
  size?: string
  thumbnails?: ImageProps[]
  title?: string
}

export const Room = memo(function Room({
  beButtonURL,
  description,
  images,
  languageCode,
  pax,
  quotationPageURL,
  size,
  thumbnails,
  title,
}: Props) {
  if (!title) {
    return null
  }

  const section = title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParam = new URLSearchParams(window.location.search).get(
        'room',
      )

      if (searchParam) {
        scroller.scrollTo(`room-${searchParam}`, {
          offset: -60,
        })
      }
    }

    return () => {
      scroller.unmount()
    }
  }, [])

  return (
    <Container id={`room-${section}`}>
      <Head dial={5} row wrap>
        <LeftSide>
          <FadeInUp>
            <Label>{useVocabularyData('room', languageCode)}</Label>
          </FadeInUp>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {pax || size ? (
            <FadeInUp>
              <Info dial={4} row wrap>
                {size ? (
                  <Size dial={4} row>
                    <Mq />
                    {size}
                  </Size>
                ) : null}
                {pax ? (
                  <Pax dial={4} row>
                    <Users />
                    {pax}
                  </Pax>
                ) : null}
              </Info>
            </FadeInUp>
          ) : null}
        </LeftSide>
        <RightSide>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </RightSide>
      </Head>
      <Wrapper>
        {images ? <Slider images={images} languageCode={languageCode} /> : null}
        {beButtonURL ? (
          <Buttons row wrap>
            {beButtonURL ? (
              <Button
                label={useVocabularyData('book', languageCode)}
                target="_blank"
                URL={beButtonURL}
                variant="outline"
              />
            ) : null}
          </Buttons>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 9.375rem;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 6.25rem;
  }
`

const Head = styled(FlexBox)`
  padding: 0 14.028vw 3.75rem;

  @media (max-width: 1199px) {
    padding: 0 6.944vw 3.75rem;
  }

  @media (max-width: 1023px) {
    padding: 0 1.875rem 3.75rem;
  }
`
const LeftSide = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const RightSide = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  line-height: 1.5rem;
  letter-spacing: 0.2rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-bottom: 0.625rem;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.75rem;
  line-height: 3.5rem;
  margin-bottom: 2rem;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 3rem;
    margin-bottom: 1.5rem;
  }
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 0.75rem;
    margin-right: 1rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
  }
`

const Size = styled(FlexBox)`
  margin-right: 2.25rem;
`

const Pax = styled(FlexBox)``

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  padding-left: 7.5rem;

  @media (max-width: 1199px) {
    padding-left: 3.75rem;
  }

  @media (max-width: 1023px) {
    line-height: 1.75rem;
    padding-left: 0;
    margin-top: 1.875rem;
  }
`

const Buttons = styled(FlexBox)`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 3.5rem;
  padding: 2.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark4};

  @media (max-width: 1023px) {
    padding: 1.875rem;
    bottom: 2.25rem;
  }

  @media (max-width: 767px) {
    background: transparent;
    padding: 0;
    bottom: 1.875rem;
  }
`

const Wrapper = styled(FlexBox)`
  padding: 0 6.667vw 7.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 22.222vh;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    width: 100%;
  }

  @media (max-width: 1023px) {
    padding: 0 1.875rem 5.625rem;
  }

  @media (max-width: 767px) {
    padding: 0 1.875rem 6.875rem;
  }
`
