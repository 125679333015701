import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { Props as RoomProps } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const Filters = memo(function Filters({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [filtersFixed, setFiltersFixed] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setFiltersFixed(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container className={filtersFixed ? 'fixed' : undefined} dial={5} row wrap>
      {uniqBy(rooms, 'title').map((item, index) => {
        if (!item.title) {
          return undefined
        }

        const section = item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

        return (
          <Filter
            activeClass="active"
            key={index}
            offset={-300}
            spy={true}
            smooth={true}
            to={`room-${section}`}
          >
            {item.labelFilter}
          </Filter>
        )
      })}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-top: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight3};
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 1.875rem;
  position: absolute;
  top: 100vh;
  left: 0;
  z-index: 3;
  &.fixed {
    animation: filtersFadeIn 0.3s;
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
    position: fixed;
    top: 5rem;
  }

  @keyframes filtersFadeIn {
    0% {
      top: 0;
    }
    100% {
      top: 5rem;
    }
  }

  @media (max-width: 1199px) {
    @keyframes filtersFadeIn {
      0% {
        top: 0;
      }
      100% {
        top: 3.75rem;
      }
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Filter = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0 3.125rem;
  position: relative;
  transition: 0.2s ease-in-out;
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }

  @media (max-width: 1439px) {
    margin: 0 1.875rem;
  }

  @media (max-width: 1199px) {
    margin: 0 1.5rem;
  }
`
