import styled from '@emotion/styled'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Filters } from './Filters'
import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (!rooms) {
    return null
  }

  return (
    <Container>
      <Filters rooms={rooms} />
      {uniqBy(rooms, 'title').map((item, index) => (
        <Room key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 0.0625rem;
    height: 100%;
    left: 50%;
    top: 0;
    bottom: 0;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  @media (max-width: 1023px) {
    &:before {
      content: none;
    }
  }
`
